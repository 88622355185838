import React, { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { AlertTriangle } from '@combateafraude/icons/general';

import PropTypes from 'prop-types';

const Observation = ({ recordData }) => {
  const { type = null, queue = null } = recordData?.record;

  const openrelatedRecordId = useCallback(() => {
    const { relatedRecordId } = recordData.record || {};

    const url = `${process.env.REACT_APP_BASE_URL_WECHECK_WEB}/${type}/records/details/record/${relatedRecordId}`;
    window.open(url, '_blank');
  }, [recordData, type]);

  const frontDigitalManipulationIndicators = [];
  const backDigitalManipulationIndicators = [];

  let frontDecision = null;
  let backDecision = null;
  let frontReason = null;
  let backReason = null;

  recordData.record?.digitalManipulationCheck?.result?.forEach((item) => {
    if (item.decision_inputs?.fraud?.data?.query_id?.split('_').pop() === 'front') {
      frontDecision = item.decision;
      frontDigitalManipulationIndicators.push(
        ...item.decision_inputs?.fraud?.data?.indicators
      );
      frontReason = item.reason?.sub_reason?.value;
    }
    if (item.decision_inputs?.fraud?.data?.query_id?.split('_').pop() === 'back') {
      backDecision = item.decision;
      backDigitalManipulationIndicators.push(
        ...item.decision_inputs?.fraud?.data?.indicators
      );
      backReason = item.reason?.sub_reason?.value;
    }
  });

  const observationData = useMemo(
    () => (
      <>
        <div className="flex">
          <p className="card-title text-bold">Motivos anteriores de reprovação</p>
        </div>

        {recordData.record?.documentscopy?.obs?.map((o) => (
          <div key={o} className="card-info">
            <span>· {o}</span>
          </div>
        ))}
      </>
    ),
    [recordData]
  );

  return (
    <>
      {queue?.solutionLevel === 'poc' && (
        <p id="poc-client-message" className="card-title max-lines-2 gx-w-100 text-bold">
          <AlertTriangle className="gx-text-danger" width={24} height={24} />
          Cliente em POC!
        </p>
      )}

      {queue?.solutionLevel === 'qrcode' && (
        <p className="card-title max-lines-2 gx-w-100 text-bold">
          Detectamos um QRCode em uma das imagens!
        </p>
      )}

      {queue?.solutionLevel === 'premium' &&
        recordData.record?.documentscopy?.doubleCheck && (
          <p className="card-title max-lines-2 gx-w-100 text-bold">
            Foi solicitado uma dupla verificação para este documento.
          </p>
        )}

      {recordData.record?.documentscopy?.obs && queue?.solutionLevel !== 'quality' && (
        <div>{observationData}</div>
      )}
      {!recordData.record?.documentscopy?.obs &&
        queue?.solutionLevel !== 'qrcode' &&
        !recordData.record?.digitalManipulationCheck?.result && (
          <p className="card-title max-lines-2 gx-w-100 text-bold">
            Nenhuma observação referente ao documento.
          </p>
        )}

      {recordData.record?.digitalManipulationCheck?.result && (
        <div>
          <p className="card-title max-lines-2 gx-w-100 text-bold">
            Digital manipulation - AI Check
          </p>
          <span className="card-title-digital-manipulation max-lines-2 mrg-btm-20">
            <strong>Final decision: </strong>
            {recordData.record?.digitalManipulationCheck?.finalDecision}
          </span>
          {frontDecision && (
            <>
              <span className="card-title-digital-manipulation max-lines-2">
                <strong>Front side decision: </strong> {frontDecision}
              </span>
              {frontReason && (
                <>
                  <span className="card-title-digital-manipulation max-lines-2">
                    <strong>Front side reason: </strong>
                    {frontReason}
                  </span>{' '}
                </>
              )}
              {frontDigitalManipulationIndicators.length > 0 && (
                <>
                  <span className="card-title-digital-manipulation max-lines-2">
                    <strong>Front side indicators:</strong>
                  </span>
                  {frontDigitalManipulationIndicators.map((indicator) => (
                    <span
                      key={indicator.indicator_id}
                      className="card-title-digital-manipulation max-lines-2"
                    >
                      · {indicator.title}
                    </span>
                  ))}
                </>
              )}
            </>
          )}
          {backDecision && (
            <>
              <span className="card-title-digital-manipulation max-lines-2">
                <strong>Back side decision: </strong> {backDecision}
              </span>
              {backReason && (
                <>
                  <span className="card-title-digital-manipulation max-lines-2">
                    <strong>Back side reason: </strong>
                    {backReason}
                  </span>{' '}
                </>
              )}
              {backDigitalManipulationIndicators.length > 0 && (
                <>
                  <span className="card-title-digital-manipulation max-lines-2">
                    <strong>Back side indicators:</strong>
                  </span>
                  {backDigitalManipulationIndicators.map((indicator) => (
                    <span
                      key={indicator.indicator_id}
                      className="card-title-digital-manipulation max-lines-2"
                    >
                      · {indicator.description}
                    </span>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      )}

      {recordData.record?.relatedRecordId && queue?.solutionLevel !== 'quality' && (
        <div className="mrg-top-10">
          <Button type="link" className="btn-link" onClick={openrelatedRecordId}>
            Abrir avaliação anterior
          </Button>
        </div>
      )}
    </>
  );
};

Observation.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
};

export default Observation;
