/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import {
  CheckCircle2,
  WrongCircle,
  Info,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';

import PropTypes from 'prop-types';

import DisplayRules from '../components/DisplayRules';
import Observation from '../components/Observation';
import ReproveDocument from '../components/ReproveDocument';
import AvaliationInfo from '../components/AvaliationInfo';
import DocumentIdentification from '../components/DocumentIdentification';
import DocumentInformation from '../components/DocumentInformation';

import './styles.less';

const { Panel } = Collapse;

const AvaliationInternationalType = ({
  recordData,
  isReview,
  reproveChanged,
  setReproveChanged,
  setAvaliationFinished,
  redirectUrl,
  selectedDocKey,
  requestedVar,
}) => {
  const { hash } = useLocation();

  const {
    avaliationInfo = null,
    documentscopy = null,
    relatedRecordId = null,
    settings = null,
    executionInfo = null,
    digitalManipulationCheck = null,
  } = recordData?.record;

  const { globalDataDocs = null } = documentscopy || {};
  const currentDocument = globalDataDocs?.[selectedDocKey];
  const { document = null, specimen = null } = currentDocument || {};
  const countryCode = document?.countryCode;
  const countryFlag = specimen?.[0]?.smallFlagUrl;

  const defaultValuesCollapse = useMemo(() => {
    const values = [];

    if (documentscopy?.obs || relatedRecordId || digitalManipulationCheck?.result) {
      values.push('obs');
    }

    if (document) {
      values.push('document-information');
    }

    if (!isReview) {
      values.push('reprove');

      if (settings?.evaluationRulesEnabled) {
        values.push('evaluationRules');
      }
    } else {
      values.push('avaliation-info');

      if (documentscopy?.review) {
        if (!documentscopy?.review.approved) {
          values.push('reprove');
        }
      }
    }
    return values;
  }, [
    documentscopy?.obs,
    documentscopy?.review,
    relatedRecordId,
    isReview,
    document,
    settings,
    digitalManipulationCheck,
  ]);

  return (
    <Collapse
      className="avaliation-international-type-collapse mrg-left-10"
      defaultActiveKey={defaultValuesCollapse}
      ghost
    >
      <DocumentIdentification
        title="Documento internacional"
        countryCode={countryCode}
        countryFlag={countryFlag}
      />

      {isReview && (
        <Panel
          header="Dados referentes a avaliação"
          key="avaliation-info"
          className={`${classNames({
            'primary-border': avaliationInfo?.evaluated && documentscopy?.approved,
            'danger-border': avaliationInfo?.evaluated && !documentscopy?.approved,
          })} mrg-btm-20`}
          extra={
            avaliationInfo?.evaluated &&
            documentscopy?.approved !== null &&
            (documentscopy?.approved ? (
              <CheckCircle2 className="gx-text-primary" width={24} height={24} />
            ) : (
              <WrongCircle className="gx-text-danger" width={24} height={24} />
            ))
          }
          expandIcon={({ isActive }) =>
            isActive ? (
              <ArrowDown width={24} height={24} />
            ) : (
              <ArrowRight width={24} height={24} />
            )
          }
        >
          <AvaliationInfo recordData={recordData} />
        </Panel>
      )}

      {settings?.evaluationRulesEnabled && (
        <Panel
          header="Regras específicas de cliente"
          key="evaluationRules"
          className={`${classNames({
            'warning-border': defaultValuesCollapse.includes('evaluationRules'),
          })} mrg-btm-20`}
        >
          <DisplayRules
            approveRules={settings.approveEvaluationRules}
            reproveRules={settings.reproveEvaluationRules}
            fantasyName={executionInfo?.fantasyName}
          />
        </Panel>
      )}
      <Panel
        header="Observações referentes ao documento"
        key="obs"
        className={`${classNames({
          'warning-border': defaultValuesCollapse.includes('obs'),
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('obs') && (
            <Info width={24} height={24} className="gx-text-warning" />
          )
        }
      >
        <Observation recordData={recordData} isReview={isReview} />
      </Panel>

      <Panel
        header="Informações de referência do documento"
        key="document-information"
        className="mrg-btm-20"
      >
        <DocumentInformation
          documentData={document}
          countryCode={countryCode}
          countryFlag={countryFlag}
        />
      </Panel>

      {!isReview && (
        <Panel
          id="reprove-component"
          header="Reprovar o documento"
          key="reprove"
          className={`${classNames({
            'danger-border': hash === '#reprove-component',
          })} mrg-btm-20`}
        >
          <ReproveDocument
            recordData={recordData}
            isReview={isReview}
            reproveChanged={reproveChanged}
            setReproveChanged={setReproveChanged}
            setAvaliationFinished={setAvaliationFinished}
            redirectUrl={redirectUrl}
            requestedVar={requestedVar}
          />
        </Panel>
      )}
    </Collapse>
  );
};

AvaliationInternationalType.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
  selectedDocKey: PropTypes.string.isRequired,
  requestedVar: PropTypes.bool,
};

AvaliationInternationalType.defaultProps = {
  isReview: false,
  reproveChanged: false,
  redirectUrl: '/manual-review-wizard',
  setReproveChanged: () => {},
  setAvaliationFinished: () => {},
  requestedVar: false,
};

export default AvaliationInternationalType;
