/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useCallback, useState, useRef } from 'react';
import { Col, Row, Form, Modal as ModalAntd, Tag, Switch } from 'antd';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import {
  Duplicate,
  Clock,
  ArrowDown,
  Home,
  ArrowLeft,
  ShieldAlert,
} from '@combateafraude/icons/general';
import { StrictEvaluation } from '@combateafraude/icons/face';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';

import Loader from 'components/Loader';
import Button from 'components/Button';
import showToastMessage from 'components/ToastMessage';
import ProgressLine from 'components/ProgressLine';

import { useAuth } from 'hooks/auth';
import { useRecord } from 'hooks/record';
import { useCountdown } from 'hooks/countdown';
import { fetchApi } from 'services/api';
import { useFetch } from 'services/hooks';

import alertImg from 'assets/images/alert-img.svg';

import Modal from 'components/Modal';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import DocumentsConfirmation from '../pages/components/DocumentsConfirmation';
import CnhDocumentsConfirmation from '../pages/components/CnhDocumentsConfirmation';
import UFYearIsEmpty from '../pages/UFYearIsEmpty';
import AvaliationRGType from '../pages/AvaliationRGType';
import AvaliationInternationalType from '../pages/AvaliationInternationalType';
import AvaliationCNHType from '../pages/AvaliationCNHType';
import AvaliationOthersType from '../pages/AvaliationOthersType';
import AvaliationError from '../pages/AvaliationError';
import Countdown from '../pages/components/Countdown';
import InternationalDocumentsConfirmation from '../pages/components/InternationalDocumentsConfirmation';

import Wrapper from '../wrapper';

import './styles.less';

const RecordDetailsEvaluationDocumentscopy = ({ id }) => {
  const { recordId } = useParams();
  const { loggedUser } = useAuth();
  const { recordData, loadingRecord, loadData, error, clearRecord } = useRecord();
  const history = useHistory();

  const [isFinalizationConfirmed, setIsFinalizationConfirmed] = useState(false);
  const [hasRequestedVar, setHasRequestedVar] = useState(false);

  const { openModal: initialPage, Modal: ModalInfoInitialPage } = Modal({
    img: alertImg,
    title: 'Termine de avaliar o documento para voltar para a página inicial!',
    okText: 'Ok',
    okType: 'primary',
  });

  const { openModal: issueModified, Modal: ModalIssueModified } = Modal({
    img: alertImg,
    title: 'Você modificou os dados de UF e/ou Ano, confirme no botão Alterar',
    okText: 'Ok',
    okType: 'primary',
  });

  const { openModal: reproveModified, Modal: ModalReproveModified } = Modal({
    img: alertImg,
    title:
      'Você selecionou motivos de reprovação, confirme no botão Reprovar abaixo do seletor',
    okText: 'Ok',
    okType: 'primary',
  });

  const { time, timeout, setTimeLast, fiveMinutes } = useCountdown();

  const [form] = Form.useForm();

  const { put, loading } = useFetch();
  const [modelId, setModelId] = useState();
  const [ufYearChanged, setUfYearChanged] = useState(false);
  const [reproveChanged, setReproveChanged] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [layoutBackData, setLayoutBackData] = useState({});
  const [qualityPhotoSignatureData, setQualityPhotoSignatureData] = useState({});
  const [blazonData, setBlazonData] = useState({});
  const [delegateSignatureData, setDelegateSignatureData] = useState({});
  const [directorSignatureData, setDirectorSignatureData] = useState({});
  const [documentType, setDocumentType] = useState();
  const [country, setCountry] = useState();

  const [avaliationFinished, setAvaliationFinished] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('/manual-review-wizard');
  const [viewMore, setViewMore] = useState(true);
  const [showButtonAllCorrect, setShowButtonAllCorrect] = useState(true);
  const [allRulesCorrect, setAllRulesCorrect] = useState({});
  const [selectedGlobalDocKey, setSelectedGlobalDocKey] = useState('front');
  const [keyTabAvaliation, setKeyTabAvaliation] = useState('front');
  const [selectedSpecimenDocIndex, setSelectedSpecimenDocIndex] = useState(0);
  const [keyTabSpecimen, setKeyTabSpecimen] = useState('0');
  const [evaluationPercent, setEvaluationPercent] = useState(50);

  const bodyRecordRef = useRef();

  useEffect(() => {
    if (recordData?.record?.queue?.solutionType === 'rg') {
      if (
        allRulesCorrect?.qualityPhotoSignature &&
        allRulesCorrect?.header &&
        allRulesCorrect?.blazon &&
        allRulesCorrect?.delegateSignature &&
        allRulesCorrect?.layoutBack
      ) {
        setShowButtonAllCorrect(false);
      }
    }

    if (recordData?.record?.queue?.solutionType === 'cnh') {
      if (allRulesCorrect?.directorSignature && allRulesCorrect?.layoutBack) {
        setShowButtonAllCorrect(false);
      }
    }
  }, [recordData, allRulesCorrect]);

  const skipEvaluation = useCallback(() => {
    const body = {
      user: {
        _id: loggedUser._id,
        name: loggedUser.name,
        username: loggedUser.username,
      },
      timeout,
      time,
      skippedAt: new Date(),
    };

    if (!avaliationFinished) {
      fetchApi(`/queuing/${recordData?.record?._id}`, 'PUT', body);
    }
  }, [avaliationFinished, loggedUser, recordData, time, timeout]);

  useBeforeunload(async () => {
    skipEvaluation();

    return undefined;
  });

  const backHome = useCallback(async () => {
    initialPage();

    setRedirectUrl('/home');
  }, [initialPage]);

  const confirmModal = useCallback(async () => {
    ModalAntd.confirm({
      className: 'confirm-finalization-modal',
      img: alertImg,
      title: 'Você confirma a finalização da avaliação?',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      width: 314,
      height: 171,
      onOk: () => {
        setIsFinalizationConfirmed(true);
      },
    });
  }, []);

  const setAllRulesForCorrect = useCallback(async () => {
    ModalAntd.confirm({
      title: 'Você realmente deseja marcar todas as regras como corretas?',
      className: 'all-rules-correct-modal',
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      width: 314,
      height: 171,
      onOk: async () => {
        setAllRulesCorrect((prevState) => ({
          ...prevState,
          qualityPhotoSignature: true,
          header: true,
          blazon: true,
          delegateSignature: true,
          additionalValidations: true,
          layoutBack: true,
        }));
      },
    });
  }, []);

  const handleValidationsBeforeSubmit = useCallback(() => {
    if (ufYearChanged) {
      issueModified();
      return false;
    }

    if (reproveChanged) {
      reproveModified();
      return false;
    }

    return true;
  }, [ufYearChanged, issueModified, reproveChanged, reproveModified]);

  const handleSubmit = useCallback(async () => {
    const payload = {
      avaliationInfo: {
        evaluated: true,
        user: {
          _id: loggedUser._id,
          name: loggedUser.name,
          username: loggedUser.username,
        },
        time,
        reviewAt: new Date(),
      },
      documentscopy: {
        approved:
          recordData?.record?.queue?.solutionType === 'international' ? true : undefined,
        fraud: false,
        unreadable: false,
        doubleCheck: recordData?.record?.documentscopy?.doubleCheck,
        qualityCheck: recordData?.record?.documentscopy?.qualityCheck,
        requestedVar: hasRequestedVar,
        review: {
          additionalValidations:
            recordData?.record?.queue?.solutionType !== 'international'
              ? {
                  header: headerData,
                  layoutBack: layoutBackData,
                  qualityPhotoSignature: qualityPhotoSignatureData,
                  blazon: blazonData,
                  delegateSignature: delegateSignatureData,
                  directorSignature: directorSignatureData,
                }
              : undefined,
        },
      },
      type: recordData?.record?.type,
    };

    if (documentType) {
      payload.documentscopy.review.documentType = documentType;
    }

    if (country) {
      payload.documentscopy.review.documentOrigin = country;
    }

    try {
      await form.validateFields();
    } catch {
      setIsFinalizationConfirmed(false);

      showToastMessage({
        type: 'error',
        text: 'Há campos obrigatórios que devem ser preenchidos',
      });

      return;
    }

    try {
      await put({
        url: `/records/${recordData.record._id}`,
        payload,
      });

      setAvaliationFinished(true);

      history.push(redirectUrl);
      window.location.reload();
    } catch (e) {
      showToastMessage({
        type: 'error',
        text: 'Erro ao registrar informações.',
      });
      setAvaliationFinished(true);
      history.replace('/home');
    }
  }, [
    loggedUser,
    time,
    recordData,
    headerData,
    layoutBackData,
    qualityPhotoSignatureData,
    blazonData,
    delegateSignatureData,
    directorSignatureData,
    form,
    put,
    history,
    redirectUrl,
    documentType,
    country,
    hasRequestedVar,
  ]);

  useEffect(() => {
    if (!isFinalizationConfirmed) {
      return;
    }

    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinalizationConfirmed]);

  const changeSelectedDoc = (newKey) => {
    setSelectedGlobalDocKey(newKey);
    setKeyTabAvaliation(newKey);
    setSelectedSpecimenDocIndex(0);
    setKeyTabSpecimen('0');
  };

  const handlePrevious = useCallback(async () => {
    setEvaluationPercent(50);

    changeSelectedDoc('front');
  }, []);

  const handleContinue = useCallback(async () => {
    setEvaluationPercent(100);

    changeSelectedDoc('back');
  }, []);

  const avaliationPage = useMemo(() => {
    if (recordData) {
      const { record = null } = recordData;

      const {
        front = null,
        back = null,
        originalFront = null,
        originalBack = null,
        originalFrontCompressed = null,
        originalBackCompressed = null,
      } = record?.executionInfo?.images;
      const containsImage =
        originalFrontCompressed ||
        originalBackCompressed ||
        front ||
        back ||
        originalFront ||
        originalBack;

      const containsGlobalImage = front || back;
      const globalImagesKeys =
        containsGlobalImage &&
        Object.keys(record?.executionInfo?.images).filter(
          (key) => (key === 'front' || key === 'back') && record.executionInfo.images[key]
        );

      return (
        <Wrapper>
          {ModalInfoInitialPage}
          {ModalIssueModified}
          {ModalReproveModified}
          <Row>
            <Col className="documentscopy record-details-cards" ref={bodyRecordRef}>
              <div className="header-record-details">
                <div className="flex">
                  <div className="flex center fd-row gx-w-100 space-between details">
                    <div className="flex center">
                      <Button
                        id="back-home-button"
                        type="link"
                        onClick={backHome}
                        className={`${classNames({
                          'back-home-enabled': redirectUrl === '/home',
                        })} btn-icon`}
                      >
                        <Home />
                      </Button>
                      <span>Id: {record._id}</span>
                      <CopyToClipboardButton
                        text={`${process.env.REACT_APP_BASE_URL_WECHECK_WEB}/${record.type}/records/details/record/${record._id}`}
                        onCopyText="Link de detalhes da execução copiado!"
                        icon={<Duplicate width={12} height={12} />}
                        size="small"
                        className="copy-record-details-link-button mrg-left-5 flex center"
                      />
                    </div>

                    <div className="flex center">
                      <div className="flex center">
                        <Clock width={24} height={24} />
                      </div>
                      <Countdown />
                    </div>
                  </div>
                </div>
                <div className="flex" style={{ justifyContent: 'center' }}>
                  {record?.settings?.flexibleEvaluation && (
                    <div className="tags-header">
                      <Tag className="flex-eval-tag">
                        <div className="icon">
                          <ShieldAlert width="20px" height="20px" />
                        </div>
                        Avaliação flexível
                      </Tag>
                    </div>
                  )}
                  {record?.queue?.solutionLevel === 'quality' && (
                    <div className="tags-header">
                      <Tag className="strict-eval-tag">
                        <div className="icon">
                          <StrictEvaluation width="20px" height="20px" />
                        </div>
                        Avaliação de qualidade
                      </Tag>
                    </div>
                  )}
                  {record?.queue?.solutionLevel === 'premium' &&
                    record?.queue?.solutionType !== 'uf_year_is_empty' &&
                    record?.documentscopy?.doubleCheck !== true && (
                      <div className="tags-header">
                        <div className="requested-var-div">
                          <Switch onChange={() => setHasRequestedVar(!hasRequestedVar)} />
                          <span>Quero dupla verificação</span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div
                className={`${classNames({
                  'pdd-top-120':
                    record?.settings?.flexibleEvaluation ||
                    record?.queue?.solutionLevel === 'quality' ||
                    (record?.queue?.solutionLevel === 'premium' &&
                      record?.queue?.solutionType !== 'uf_year_is_empty' &&
                      record?.documentscopy?.doubleCheck !== true),
                  'pdd-btm-150': showButtonAllCorrect,
                  'pdd-btm-90': !showButtonAllCorrect,
                  'pdd-btm-btm': !containsImage,
                })} body-record-details body-record-details-evaluation`}
              >
                {!containsImage && (
                  <AvaliationError
                    recordData={recordData}
                    reproveChanged={reproveChanged}
                    setReproveChanged={setReproveChanged}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                  />
                )}
                {record?.queue?.solutionType === 'uf_year_is_empty' && containsImage && (
                  <UFYearIsEmpty
                    recordData={recordData}
                    ufYearChanged={ufYearChanged}
                    setUfYearChanged={setUfYearChanged}
                    reproveChanged={reproveChanged}
                    setReproveChanged={setReproveChanged}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                  />
                )}
                {record?.queue?.solutionType === 'rg' && containsImage && (
                  <AvaliationRGType
                    recordData={recordData}
                    modelId={modelId}
                    formRef={form}
                    ufYearChanged={ufYearChanged}
                    setUfYearChanged={setUfYearChanged}
                    reproveChanged={reproveChanged}
                    setReproveChanged={setReproveChanged}
                    setHeaderData={setHeaderData}
                    setLayoutBackData={setLayoutBackData}
                    setQualityPhotoSignatureData={setQualityPhotoSignatureData}
                    setBlazonData={setBlazonData}
                    setDelegateSignatureData={setDelegateSignatureData}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                    allRulesCorrect={allRulesCorrect}
                    setAllRulesCorrect={setAllRulesCorrect}
                    requestedVar={hasRequestedVar}
                  />
                )}
                {record?.queue?.solutionType === 'cnh' && containsImage && (
                  <AvaliationCNHType
                    recordData={recordData}
                    formRef={form}
                    modelId={modelId}
                    ufYearChanged={ufYearChanged}
                    setUfYearChanged={setUfYearChanged}
                    setDirectorSignatureData={setDirectorSignatureData}
                    reproveChanged={reproveChanged}
                    setReproveChanged={setReproveChanged}
                    setLayoutBackData={setLayoutBackData}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                    allRulesCorrect={allRulesCorrect}
                    setAllRulesCorrect={setAllRulesCorrect}
                    requestedVar={hasRequestedVar}
                  />
                )}
                {record?.queue?.solutionType === 'international' &&
                  containsGlobalImage && (
                    <AvaliationInternationalType
                      recordData={recordData}
                      formRef={form}
                      reproveChanged={reproveChanged}
                      setReproveChanged={setReproveChanged}
                      setAvaliationFinished={setAvaliationFinished}
                      redirectUrl={redirectUrl}
                      selectedDocKey={selectedGlobalDocKey}
                      selectedSpecimenDocIndex={selectedSpecimenDocIndex}
                      requestedVar={hasRequestedVar}
                    />
                  )}
                {record?.queue?.solutionType === 'others' && containsImage && (
                  <AvaliationOthersType
                    recordData={recordData}
                    reproveChanged={reproveChanged}
                    setReproveChanged={setReproveChanged}
                    setAvaliationFinished={setAvaliationFinished}
                    redirectUrl={redirectUrl}
                    documentType={documentType}
                    setDocumentType={setDocumentType}
                    country={country}
                    setCountry={setCountry}
                    requestedVar={hasRequestedVar}
                  />
                )}
              </div>

              <div className="footer-record-details">
                <div>
                  {viewMore && (
                    <div className="view-more-details-component">
                      <ArrowDown width={24} height={24} />
                    </div>
                  )}
                  {containsImage && record?.queue?.solutionLevel !== 'international' && (
                    <div
                      className={`${classNames({
                        'btn-finished-true':
                          record?.queue?.solutionType !== 'uf_year_is_empty',
                        'btn-finished-false':
                          record?.queue?.solutionType === 'uf_year_is_empty',
                      })} footer-buttons`}
                    >
                      {record?.queue?.solutionType !== 'uf_year_is_empty' && (
                        <Button
                          onClick={() => {
                            const canSubmit = handleValidationsBeforeSubmit();

                            if (canSubmit) {
                              handleSubmit();
                            }
                          }}
                          className="btn-finished btn-custom-primary"
                        >
                          Finalizar
                        </Button>
                      )}
                      {(record?.queue?.solutionType === 'rg' ||
                        record?.queue?.solutionType === 'cnh') &&
                        showButtonAllCorrect && (
                          <Button
                            onClick={setAllRulesForCorrect}
                            className="btn-custom-secondary btn-all-rules-correct"
                          >
                            Aprovar de vez
                          </Button>
                        )}

                      <a href="#reprove-component" type="button" className="btn-reprove">
                        Reprovar
                      </a>
                    </div>
                  )}
                  {containsGlobalImage &&
                    record?.queue?.solutionLevel === 'international' && (
                      <div className="footer-buttons">
                        {globalImagesKeys?.length > 1 && (
                          <>
                            <Button
                              onClick={handlePrevious}
                              className="btn-icon"
                              disabled={selectedGlobalDocKey === 'front'}
                            >
                              <ArrowLeft />
                            </Button>
                            <ProgressLine
                              className="evaluation-progress-bar"
                              steps={globalImagesKeys?.length}
                              percent={evaluationPercent}
                            />
                          </>
                        )}
                        {globalImagesKeys?.length > 1 &&
                        selectedGlobalDocKey === 'front' ? (
                          <Button
                            onClick={handleContinue}
                            className="btn-finished btn-custom-secondary"
                          >
                            Continuar
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              const canConfirmFinalization =
                                handleValidationsBeforeSubmit();

                              if (canConfirmFinalization) {
                                confirmModal();
                              }
                            }}
                            className="btn-finished btn-custom-primary"
                          >
                            Finalizar
                          </Button>
                        )}

                        <a
                          href="#reprove-component"
                          type="button"
                          className="btn-reprove"
                        >
                          Reprovar
                        </a>
                      </div>
                    )}
                </div>
              </div>
            </Col>

            <Col>
              {record?.queue?.solutionLevel === 'international' ? (
                <InternationalDocumentsConfirmation
                  recordData={recordData}
                  selectedDocKey={selectedGlobalDocKey}
                  setSelectedDocKey={setSelectedGlobalDocKey}
                  keyTabAvaliation={keyTabAvaliation}
                  setKeyTabAvaliation={setKeyTabAvaliation}
                  selectedSpecimenDocIndex={selectedSpecimenDocIndex}
                  setSelectedSpecimenDocIndex={setSelectedSpecimenDocIndex}
                  keyTabSpecimen={keyTabSpecimen}
                  setKeyTabSpecimen={setKeyTabSpecimen}
                />
              ) : record?.queue?.solutionType === 'cnh' ? (
                <CnhDocumentsConfirmation
                  recordData={recordData}
                  setModelId={setModelId}
                  modelId={modelId}
                />
              ) : (
                <DocumentsConfirmation
                  recordData={recordData}
                  setModelId={setModelId}
                  modelId={modelId}
                />
              )}
            </Col>
          </Row>
        </Wrapper>
      );
    }

    return <></>;
  }, [
    recordData,
    ModalInfoInitialPage,
    ModalIssueModified,
    ModalReproveModified,
    reproveChanged,
    redirectUrl,
    ufYearChanged,
    modelId,
    form,
    documentType,
    country,
    viewMore,
    showButtonAllCorrect,
    setAllRulesForCorrect,
    handleSubmit,
    backHome,
    allRulesCorrect,
    setAllRulesCorrect,
    selectedGlobalDocKey,
    selectedSpecimenDocIndex,
    handleContinue,
    keyTabAvaliation,
    evaluationPercent,
    handlePrevious,
    keyTabSpecimen,
    handleValidationsBeforeSubmit,
    confirmModal,
    hasRequestedVar,
  ]);

  useEffect(() => {
    const bodyRecord = bodyRecordRef?.current;

    if (bodyRecord) {
      if (bodyRecord.scrollHeight > bodyRecord.clientHeight) {
        bodyRecord.addEventListener('scroll', () => {
          const { scrollTop, scrollHeight, clientHeight } = bodyRecordRef.current;
          if (scrollTop + clientHeight === scrollHeight) {
            setViewMore(false);
          } else {
            setViewMore(true);
          }
        });
      } else {
        setViewMore(false);
      }
    }
  }, [recordData]);

  useEffect(() => {
    const idData = id || recordId;

    loadData({ recordId: idData, clearData: true });
  }, [loadData, id, recordId, clearRecord]);

  useEffect(() => {
    setTimeLast(fiveMinutes);
  }, [fiveMinutes, setTimeLast]);

  const hasRecordError = useMemo(() => {
    const formattedErrorStatusCode = error?.statusCode;

    return ['500', '400'].includes(formattedErrorStatusCode);
  }, [error]);

  useEffect(() => {
    if (hasRecordError) {
      showToastMessage({
        type: 'error',
        text: `${error.data.message}`,
      });
    }
  }, [error, hasRecordError]);

  return (
    <div id="record-details-component">
      {(loadingRecord && !recordData) || loading || avaliationFinished ? (
        <div id="page-loading">
          <Loader size="large" />
        </div>
      ) : (
        <>
          {hasRecordError ? (
            <>
              <Redirect to="/home" />
              {clearRecord({ shouldClearError: true })}
            </>
          ) : (
            <>
              {recordData && (
                <div className="gx-flex-row">
                  <Form form={form} autoComplete="off">
                    {avaliationPage}
                  </Form>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

RecordDetailsEvaluationDocumentscopy.propTypes = {
  id: PropTypes.string,
};

RecordDetailsEvaluationDocumentscopy.defaultProps = {
  id: null,
};

export default RecordDetailsEvaluationDocumentscopy;
