/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Collapse, DatePicker, Tooltip, Checkbox, Tag, Form, Radio } from 'antd';
import Loader from 'components/Loader';
import _ from 'lodash';
import moment from 'moment';
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  withDefault,
  NumberParam,
} from 'use-query-params';

import { useCustomer } from 'hooks/customer';
import { useAuth } from 'hooks/auth';
import { useDisapprovalReasons } from 'hooks/disapprovalReasons';

import { toMaskedCnpj } from 'utils/formatters';

import customerStatusList from 'pages/private/Review/ReviewFilter/utils/customerStatusList.json';

import Button from 'components/Button';
import { hasRequiredPermissions } from 'routes/permissionFunctions';

import SearchInput from 'components/Form/SearchInput';
import {
  Checkmark,
  CircleFalse,
  CheckCircle2,
  Search,
  File,
  Calendar,
  ArrowDown,
  ArrowRight,
} from '@combateafraude/icons/general';

import { CursorSelect } from '@combateafraude/icons/builder';
import { creationDateDates } from './utils';

import SelectCustomFilter from './components/SelectCustomFilter';
import SelectCreationDate from './components/SelectCreationDate';

import './styles.less';

const { Panel } = Collapse;
const { Option } = Select;
const { YearPicker, MonthPicker } = DatePicker;

const ReviewFilter = ({ isLoadingList }) => {
  const { loggedUser } = useAuth();
  const [form] = Form.useForm();
  const [hasPermissionFilterEmail, setHasPermissionFilter] = useState(false);
  const [hasPermissionIncorrectAnalysis, setHasPermissionIncorrectAnalysis] =
    useState(false);
  const [hasPermissionReportSuspect, setHasPermissionReportSuspect] = useState(false);

  const [query, setQuery] = useQueryParams({
    lifetime: StringParam,
    reviewedAt: StringParam,
    creationDate: StringParam,
    startDate: NumberParam,
    endDate: NumberParam,
    recordTypes: withDefault(ArrayParam, []),
    evaluationStatus: StringParam,
    email: StringParam,
    approvalStatus: StringParam,
    documentType: StringParam,
    issueState: StringParam,
    issueYear: StringParam,
    issueMonth: StringParam,
    disapprovalReason: StringParam,
    specificDisapprovalReasons: withDefault(ArrayParam, []),
    doubleCheck: StringParam,
    incorrectAnalysis: StringParam,
    reportSuspect: StringParam,
    tenantId: withDefault(ArrayParam, []),
    _order: StringParam,
    _sort: StringParam,
  });

  const { getCustomers, customersData: customers, loadingCustomers } = useCustomer();
  const {
    getData: getSpecificDisapprovalReasons,
    dataDocs: specificDisapprovalReasons,
    loading: loadingSpecificDisapprovalReasons,
  } = useDisapprovalReasons();

  const [emailFilter, setEmailFilter] = useState(() => query.email || null);

  const [searchCustomer, setSearchCustomer] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customersSearched, setCustomersSearched] = useState([]);

  const [tenantIdFilter, setTenantIdFilter] = useState(() =>
    query.tenantId && Object.keys(query.tenantId || {}).length !== 0
      ? query.tenantId?.map((c) => ({ key: c, value: c }))
      : []
  );

  const [searchSpecificReason, setSearchSpecificReason] = useState(null);
  const [allSpecificReasons, setAllSpecificReasons] = useState([]);
  const [specificReasonsSearched, setSpecificReasonsSearched] = useState([]);

  const [specificDisapprovalReasonsFilter, setSpecificDisapprovalReasonsFilter] =
    useState(() =>
      query.specificDisapprovalReasons &&
      Object.keys(query.specificDisapprovalReasons || {}).length !== 0
        ? query.specificDisapprovalReasons?.map((reason) => ({
            key: reason,
            value: reason,
          }))
        : []
    );

  const [lifetime, setLifetime] = useState(() => query.lifetime || []);

  const [createdAtFilter, setCreatedAtFilter] = useState(
    () => query.creationDate || 'last24Hours'
  );
  const [customCreatedAtFilter, setCustomCreatedAtFilter] = useState(
    () => query.creationDate || 'custom'
  );

  const [reviewedAtFilter, setReviewedAtFilter] = useState(
    () => query.reviewedAt || 'false'
  );

  const [recordTypesFilter, setRecordTypesFilter] = useState(() =>
    query.recordTypes && Object.keys(query.recordTypes || {}).length !== 0
      ? query.recordTypes?.map((c) => ({ key: c, value: c }))
      : []
  );

  const [evaluationStatusFilter, setEvaluationStatusFilter] = useState(
    () => query.evaluationStatus || undefined
  );

  const [approvalStatusFilter, setApprovalStatusFilter] = useState(
    () => query.approvalStatus || undefined
  );

  const [documentTypeFilter, setDocumentTypeFilter] = useState(
    () => query.documentType || undefined
  );

  const [issueStateFilter, setIssueStateFilter] = useState(
    () => query.issueState || undefined
  );

  const [issueYearFilter, setIssueYearFilter] = useState(
    () => query.issueYear || undefined
  );

  const [issueMonthFilter, setIssueMonthFilter] = useState(
    () => query.issueMonth || undefined
  );

  const [disapprovalReasonFilter, setDisapprovalReasonFilter] = useState(
    () => query.disapprovalReason || undefined
  );

  const [doubleCheckFilter, setDoubleCheckFilter] = useState(
    () => query.doubleCheck === 'true' || undefined
  );

  const [incorrectAnalysisFilter, setIncorrectAnalysisFilter] = useState(
    () => query.incorrectAnalysis || 'all'
  );

  const [reportSuspectFilter, setReportSuspectFilter] = useState(
    () => query.reportSuspect || 'all'
  );

  const cnpjFormattedToSearch = useCallback((cnpj) => {
    if (!cnpj) return undefined;

    const cnpjFormatted = cnpj
      ?.replaceAll('.', '')
      ?.replaceAll('/', '')
      ?.replaceAll('-', '');

    return cnpjFormatted;
  }, []);

  const setFilter = useCallback(
    (filterName, event) => {
      const fiveMinutes = 5 * 60 * 1000;
      let queryParams = {
        [filterName]: event && !_.isEmpty(event) ? event : undefined,
      };

      if ((createdAtFilter || customCreatedAtFilter) && filterName === 'creationDate') {
        if (Array.isArray(event)) {
          const { startDate, endDate } = event[0];

          queryParams = {
            [filterName]: `custom:${startDate}-${endDate}`,
            startDate,
            endDate,
          };
        } else {
          const date = creationDateDates(event);
          const { startDate } = date;
          let { endDate } = date;

          if (lifetime[0] === 'fiveMin' || lifetime === 'fiveMin') {
            endDate -= fiveMinutes; // - 5 mintutos
          }

          queryParams = {
            [filterName]: event && !_.isEmpty(event) ? event : undefined,
            startDate,
            endDate,
          };
        }
      }
      if (filterName === 'recordTypes' || filterName === 'customers') {
        queryParams = {
          [filterName]:
            event && !_.isEmpty(event) ? event.map((val) => val.key) : undefined,
        };
      }
      if (filterName === 'tenantId' || filterName === 'specificDisapprovalReasons') {
        queryParams = {
          [filterName]:
            event && !_.isEmpty(event) ? event.map((val) => val.value) : undefined,
        };
      }

      if (filterName === 'doubleCheck') {
        queryParams = {
          [filterName]: event === true || undefined,
        };
      }

      setQuery(queryParams);
    },
    [createdAtFilter, lifetime, customCreatedAtFilter, setQuery]
  );

  const onCustomerSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setCustomersSearched(allCustomers);
      }

      const customersFiltered = allCustomers?.filter((customer) => {
        const cnpjFormatted = cnpjFormattedToSearch(customer?.cnpj);

        if (
          customer?.fantasyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          customer?.companyName?.toUpperCase().includes(searchTerm?.toUpperCase()) ||
          cnpjFormatted?.includes(searchTerm)
        ) {
          return customer;
        }

        return false;
      });

      setCustomersSearched(customersFiltered || []);
    },
    [allCustomers, cnpjFormattedToSearch]
  );

  const onSpecificReasonsSearch = useCallback(
    (searchTerm) => {
      if (searchTerm === '') {
        setSpecificReasonsSearched(allSpecificReasons);
      }

      const specificReasonsFiltered = allSpecificReasons?.filter((reason) => {
        if (
          reason?.disapprovalMessage?.toUpperCase().includes(searchTerm?.toUpperCase())
        ) {
          return reason;
        }

        return false;
      });

      setSpecificReasonsSearched(specificReasonsFiltered || []);
    },
    [allSpecificReasons]
  );

  const updateFilters = useCallback(() => {
    setFilter('lifetime', lifetime);
    setFilter('reviewedAt', reviewedAtFilter);
    setFilter('creationDate', createdAtFilter);
    if (createdAtFilter?.substr(0, 6) === 'custom') {
      setFilter('creationDate', customCreatedAtFilter);
      setFilter('lifetime', []);
    } else {
      setFilter('creationDate', createdAtFilter);
    }
    setFilter('recordTypes', recordTypesFilter);
    setFilter('evaluationStatus', evaluationStatusFilter);
    setFilter('approvalStatus', approvalStatusFilter);
    setFilter('documentType', documentTypeFilter);
    setFilter('issueState', issueStateFilter);
    setFilter('issueYear', issueYearFilter);
    setFilter('issueMonth', issueMonthFilter);
    setFilter('disapprovalReason', disapprovalReasonFilter);
    setFilter('specificDisapprovalReasons', specificDisapprovalReasonsFilter);
    setFilter('email', emailFilter);
    setFilter('doubleCheck', doubleCheckFilter);
    setFilter('incorrectAnalysis', incorrectAnalysisFilter);
    setFilter('reportSuspect', reportSuspectFilter);
    setFilter('tenantId', tenantIdFilter);
    setSearchCustomer(null);
    setSearchSpecificReason(null);
  }, [
    setFilter,
    lifetime,
    reviewedAtFilter,
    createdAtFilter,
    recordTypesFilter,
    evaluationStatusFilter,
    approvalStatusFilter,
    documentTypeFilter,
    issueStateFilter,
    issueYearFilter,
    issueMonthFilter,
    disapprovalReasonFilter,
    specificDisapprovalReasonsFilter,
    emailFilter,
    doubleCheckFilter,
    incorrectAnalysisFilter,
    reportSuspectFilter,
    customCreatedAtFilter,
    tenantIdFilter,
  ]);

  const resetFilters = useCallback(() => {
    setQuery({
      recordTypes: undefined,
      evaluationStatus: undefined,
      approvalStatus: undefined,
      documentType: undefined,
      issueState: undefined,
      issueYear: undefined,
      issueMonth: undefined,
      disapprovalReason: undefined,
      specificDisapprovalReasons: undefined,
      lifetime: [],
      reviewedAt: undefined,
      email: undefined,
      doubleCheck: undefined,
      incorrectAnalysis: 'all',
      reportSuspect: 'all',
      tenantId: undefined,
    });

    setRecordTypesFilter(() => []);
    setEvaluationStatusFilter(() => undefined);
    setApprovalStatusFilter(() => undefined);
    setDocumentTypeFilter(() => undefined);
    setIssueStateFilter(() => undefined);
    setIssueYearFilter(() => undefined);
    setIssueMonthFilter(() => undefined);
    setDisapprovalReasonFilter(() => undefined);
    setSpecificDisapprovalReasonsFilter(() => undefined);
    setLifetime(() => []);
    setReviewedAtFilter(() => undefined);
    setEmailFilter(() => undefined);
    form.setFieldsValue({
      reviewEmailFilter: '',
    });
    setDoubleCheckFilter(() => undefined);
    setIncorrectAnalysisFilter(() => 'all');
    setReportSuspectFilter(() => 'all');
    setTenantIdFilter(() => undefined);
  }, [setQuery, form]);

  const approvalStatusIsNotReproved = useMemo(
    () => approvalStatusFilter !== 'reproved',
    [approvalStatusFilter]
  );

  const evaluationStatusIsNotEvaluated = useMemo(
    () => evaluationStatusFilter !== 'evaluated',
    [evaluationStatusFilter]
  );

  const disapprovalReasonIsSelected = useMemo(
    () => disapprovalReasonFilter,
    [disapprovalReasonFilter]
  );

  const activeFilterKeys = useMemo(() => {
    const keys = [];
    if (recordTypesFilter.length > 0) {
      keys.push('recordTypes');
    }

    if (evaluationStatusFilter) {
      keys.push('evaluationStatus');
    }

    if (approvalStatusFilter) {
      keys.push('approvalStatus');
    }

    if (documentTypeFilter) {
      keys.push('documentType');
    }

    if (issueStateFilter) {
      keys.push('issueState');
    }

    if (issueYearFilter) {
      keys.push('issueYear');
    }

    if (issueMonthFilter) {
      keys.push('issueMonth');
    }

    if (disapprovalReasonFilter) {
      keys.push('disapprovalReason');
    }

    if (specificDisapprovalReasonsFilter) {
      keys.push('specificDisapprovalReasons');
    }

    if (tenantIdFilter) {
      keys.push('tenantId');
    }

    return keys;
  }, [
    recordTypesFilter,
    evaluationStatusFilter,
    approvalStatusFilter,
    documentTypeFilter,
    issueStateFilter,
    issueYearFilter,
    issueMonthFilter,
    disapprovalReasonFilter,
    specificDisapprovalReasonsFilter,
    tenantIdFilter,
  ]);
  useEffect(() => {
    setHasPermissionFilter(
      hasRequiredPermissions(loggedUser, ['wecheck_emailFilter:read'], [])
    );
    setHasPermissionIncorrectAnalysis(
      hasRequiredPermissions(loggedUser, ['wecheck_incorrectAnalysis:read'], [])
    );
    setHasPermissionReportSuspect(
      hasRequiredPermissions(loggedUser, ['wecheck_reportSuspect:read'], [])
    );
  }, [loggedUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await getCustomers();
    await getSpecificDisapprovalReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const customersFiltered = customers?.docs?.filter(
      (customer) => customer?.fantasyName || customer?.companyName
    );

    setAllCustomers(customersFiltered || []);
    setCustomersSearched(customersFiltered || []);
  }, [customers]);

  useEffect(() => {
    const specificReasonsFiltered = specificDisapprovalReasons
      ?.filter((reason) => reason?.disapprovalMessage)
      ?.filter((reason) => {
        if (disapprovalReasonFilter === 'fraud') {
          return reason.fraud;
        }

        if (disapprovalReasonFilter === 'unreadable') {
          return reason.unreadable;
        }

        return !reason.fraud && !reason.unreadable;
      });

    setAllSpecificReasons(specificReasonsFiltered || []);
    setSpecificReasonsSearched(specificReasonsFiltered || []);
  }, [specificDisapprovalReasons, disapprovalReasonFilter]);

  return (
    <div id="custom-filter-component">
      {hasPermissionFilterEmail ? (
        <Form form={form} autoComplete="off">
          <SearchInput
            name="reviewEmailFilter"
            value={emailFilter}
            placeholder="Busque por email..."
            prefix={<Search width={16} height={16} />}
            onChange={(e) => {
              setEmailFilter(e.target.value);
            }}
            style={{ width: 291 }}
          />
        </Form>
      ) : (
        <div className="flex start-center select-custom-icon">
          <CursorSelect width={24} height={24} />
          <Select
            labelInValue
            showArrow={false}
            allowClear
            onChange={(e) => {
              setEmailFilter(e?.value || null);
            }}
            placeholder="Selecione seu email"
            optionLabelProp="label"
            dropdownClassName="multiselect-filters"
            filterOption={false}
            listHeight={320}
            dropdownAlign={{ offset: [0, 2] }}
            removeIcon={<CircleFalse />}
            menuItemSelectedIcon={<Checkmark />}
            style={{ width: 291 }}
          >
            <Option key={loggedUser.username} label={loggedUser.username}>
              {loggedUser.username}
            </Option>
          </Select>
        </div>
      )}
      <Collapse
        defaultActiveKey={['creationDate', ...activeFilterKeys]}
        ghost
        expandIconPosition="end"
        expandIcon={({ isActive }) =>
          isActive ? (
            <ArrowDown width={24} height={24} />
          ) : (
            <ArrowRight width={24} height={24} />
          )
        }
      >
        {hasPermissionIncorrectAnalysis && (
          <Panel header="Análises incorretas" key="incorrectAnalysis">
            <Radio.Group
              value={incorrectAnalysisFilter}
              onChange={(e) => {
                setIncorrectAnalysisFilter(e.target.value);
              }}
              className="flex-column center-start"
            >
              <Radio value="all">Todos</Radio>
              <Radio value="true">Já marcadas</Radio>
              <Radio value="false">Não marcadas</Radio>
            </Radio.Group>
          </Panel>
        )}

        {hasPermissionReportSuspect && (
          <Panel header="Reportes de suspeitos" key="reportSuspect">
            <Radio.Group
              value={reportSuspectFilter}
              onChange={(e) => {
                setReportSuspectFilter(e.target.value);
              }}
              className="flex-column center-start"
            >
              <Radio value="all">Todos</Radio>
              <Radio value="true">Já reportados</Radio>
              <Radio value="false">Não reportados</Radio>
            </Radio.Group>
          </Panel>
        )}

        <Panel className="customers-filter-panel" header="Por cliente(s)" key="tenantId">
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <Select
              mode="multiple"
              labelInValue
              value={tenantIdFilter}
              placeholder="Busque por nome ou e-mail..."
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={
                <Checkmark width={16} height={16} className="gx-text-primary" />
              }
              loading={loadingCustomers}
              suffixIcon={<Loader />}
              bordered={false}
              className="custom-input"
              notFoundContent={
                !loadingCustomers && !customers?.docs?.length ? (
                  <span>
                    {searchCustomer
                      ? `Nenhum resultado encontrado para "${searchCustomer}"`
                      : 'Busque clientes por nome ou e-mail'}
                  </span>
                ) : null
              }
              onSearch={onCustomerSearch}
              onClear={() => setCustomersSearched(allCustomers)}
              onBlur={() => {
                setSearchCustomer(null);
                setCustomersSearched(allCustomers);
              }}
              onChange={(values) => setTenantIdFilter(values)}
              style={{ width: 291 }}
            >
              {customersSearched?.map((c) => (
                <Option
                  key={c._id}
                  label={c.fantasyName || c.companyName}
                  value={c.tenantId}
                >
                  <div className="multiselect-option">
                    <span className="option-title">{c.fantasyName || c.companyName}</span>
                    <div className="flex start-center">
                      <span className="option-subtitle">{toMaskedCnpj(c.cnpj)}</span>
                      <section className="mrg-left-10">
                        <>
                          {c?.verified && (
                            <Tooltip title="Em produção">
                              <CheckCircle2
                                className="gx-text-success"
                                width={16}
                                height={16}
                              />
                            </Tooltip>
                          )}
                          {c?.hasContract && (
                            <Tooltip title="Possui contrato">
                              <File />
                            </Tooltip>
                          )}
                          {c?.status && (
                            <Tag
                              className="mrg-left-5 font-size-10"
                              color={customerStatusList[c?.status || 'lead'].color}
                            >
                              {c?.status === 'internal-customer'
                                ? 'Interno'
                                : customerStatusList[c?.status || 'lead'].name}
                            </Tag>
                          )}
                        </>
                      </section>
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
        </Panel>
        <Panel header="Por data" key="creationDate">
          <SelectCreationDate
            lifetime={lifetime}
            setLifetime={setLifetime}
            setCreatedAtFilter={setCreatedAtFilter}
            reviewedAt={reviewedAtFilter}
            createdAtFilter={createdAtFilter}
            setReviewedAtFilter={setReviewedAtFilter}
            customCreatedAtFilter={customCreatedAtFilter}
            setCustomCreatedAtFilter={setCustomCreatedAtFilter}
          />
        </Panel>
        <Panel header="Tipos de registro" key="recordTypes">
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <SelectCustomFilter
              mode="multiple"
              bordered={false}
              className="custom-input"
              allowClear
              value={recordTypesFilter}
              setFilter={setRecordTypesFilter}
              placeholder="Selecione tipo(s) de registro"
              options={[
                { key: 'documentscopy', label: 'Documentoscopia' },
                { key: 'pending-ocr', label: 'Pendente OCR' },
                { key: 'double-check-liveness', label: 'Double-check de Liveness' },
              ]}
            />
          </div>
        </Panel>
        <Panel header="Status da Avaliação" key="evaluationStatus">
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <Select
              labelInValue
              showArrow={false}
              allowClear
              value={evaluationStatusFilter}
              onChange={(value) => {
                if (!value || value.key === 'waiting-evaluation') {
                  setApprovalStatusFilter(() => undefined);
                  setDisapprovalReasonFilter(() => undefined);
                  setSpecificDisapprovalReasonsFilter(() => undefined);
                }
                setEvaluationStatusFilter(value?.key);
              }}
              placeholder="Selecione o status de avaliação"
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<Checkmark width={20} height={20} />}
              style={{ width: 291 }}
              bordered={false}
              className="custom-input"
            >
              <Option key="evaluated" label="Avaliado">
                Avaliado
              </Option>
              <Option key="waiting-evaluation" label="Aguardando avaliação">
                Aguardando avaliação
              </Option>
            </Select>
          </div>
        </Panel>
        <Panel
          header={
            !evaluationStatusIsNotEvaluated ? (
              'Status da Aprovação'
            ) : (
              <Tooltip
                title='Status da Avaliação precisa ser "Avaliado"'
                overlayClassName="review-filter-tooltip"
              >
                Status da Aprovação
              </Tooltip>
            )
          }
          key="approvalStatus"
          collapsible={!evaluationStatusIsNotEvaluated ? undefined : 'disabled'}
        >
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <Select
              labelInValue
              showArrow={false}
              allowClear
              value={approvalStatusFilter}
              onChange={(value) => {
                if (!value || value.key === 'approved') {
                  setDisapprovalReasonFilter(() => undefined);
                  setSpecificDisapprovalReasonsFilter(() => undefined);
                }
                setApprovalStatusFilter(value?.key);
              }}
              placeholder="Selecione o status de aprovação"
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<Checkmark width={20} height={20} />}
              style={{ width: 291 }}
              disabled={evaluationStatusIsNotEvaluated}
              bordered={false}
              className="custom-input"
            >
              <Option key="approved" label="Aprovado">
                Aprovado
              </Option>
              <Option key="reproved" label="Reprovado">
                Reprovado
              </Option>
            </Select>
          </div>
        </Panel>
        <Panel
          header={
            !approvalStatusIsNotReproved && !evaluationStatusIsNotEvaluated ? (
              'Motivo da reprovação'
            ) : (
              <Tooltip
                title='Status da Aprovação precisa ser "Reprovado"'
                overlayClassName="review-filter-tooltip"
              >
                Motivo da reprovação
              </Tooltip>
            )
          }
          key="disapprovalReason"
          collapsible={
            !approvalStatusIsNotReproved && !evaluationStatusIsNotEvaluated
              ? undefined
              : 'disabled'
          }
        >
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <Select
              labelInValue
              showArrow={false}
              allowClear
              value={disapprovalReasonFilter}
              onChange={(value) => {
                setSpecificDisapprovalReasonsFilter(() => undefined);
                setDisapprovalReasonFilter(value?.key);
              }}
              placeholder="Selecione o motivo de reprovação"
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<Checkmark />}
              style={{ width: 291 }}
              disabled={approvalStatusIsNotReproved}
              bordered={false}
              className="custom-input"
            >
              <Option key="fraud" label="Fraude">
                Fraude
              </Option>
              <Option key="unreadable" label="Ilegibilidade">
                Ilegibilidade
              </Option>
              <Option key="general" label="Geral">
                Geral
              </Option>
            </Select>
          </div>
          {disapprovalReasonIsSelected && (
            <Select
              className="specific-reasons-select custom-input"
              mode="multiple"
              labelInValue
              showArrow={false}
              value={specificDisapprovalReasonsFilter}
              onChange={(values) => {
                setSpecificDisapprovalReasonsFilter(values);
              }}
              placeholder="Selecione o(s) motivo(s) específico(s) de reprovação"
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<></>}
              loading={loadingSpecificDisapprovalReasons}
              notFoundContent={
                !loadingSpecificDisapprovalReasons &&
                !specificDisapprovalReasons?.length ? (
                  <span>
                    {searchSpecificReason
                      ? `Nenhum resultado encontrado para "${searchSpecificReason}"`
                      : 'Busque motivos específicos por nome'}
                  </span>
                ) : null
              }
              onSearch={onSpecificReasonsSearch}
              showSearch
              onClear={() => setSpecificReasonsSearched(allSpecificReasons)}
              onBlur={() => {
                setSearchSpecificReason(null);
                setSpecificReasonsSearched(allSpecificReasons);
              }}
              style={{ width: 291 }}
              bordered={false}
            >
              {specificReasonsSearched.map((reason) => (
                <Option
                  key={reason.disapprovalMessageId}
                  label={reason.disapprovalMessage}
                  value={reason.disapprovalMessageId}
                >
                  {reason.disapprovalMessage}
                </Option>
              ))}
            </Select>
          )}
        </Panel>
        <Panel header="Tipo de documento" key="documentType">
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <Select
              labelInValue
              showArrow={false}
              allowClear
              value={documentTypeFilter}
              onChange={(value) => {
                setDocumentTypeFilter(value?.key);
              }}
              placeholder="Selecione o tipo de documento"
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<Checkmark width={20} height={20} />}
              style={{ width: 291 }}
              bordered={false}
              className="custom-input"
            >
              <Option key="rg" label="RG">
                RG
              </Option>
              <Option key="cnh" label="CNH">
                CNH
              </Option>
              <Option key="rnm" label="RNM">
                RNM
              </Option>
              <Option key="rne" label="RNE">
                RNE
              </Option>
              <Option key="international" label="INTERNACIONAL">
                INTERNACIONAL
              </Option>
              <Option key="others" label="OUTROS">
                OUTROS
              </Option>
            </Select>
          </div>
        </Panel>
        <Panel header="UF do documento" key="issueState">
          <div className="flex start-center select-custom-icon">
            <CursorSelect width={24} height={24} />
            <Select
              labelInValue
              showArrow={false}
              allowClear
              value={issueStateFilter}
              onChange={(value) => {
                setIssueStateFilter(value?.key);
              }}
              placeholder="Selecione a UF do documento"
              optionLabelProp="label"
              dropdownClassName="multiselect-filters"
              filterOption={false}
              listHeight={320}
              dropdownAlign={{ offset: [0, 2] }}
              removeIcon={<CircleFalse />}
              menuItemSelectedIcon={<Checkmark width={20} height={20} />}
              style={{ width: 291 }}
              bordered={false}
              className="custom-input"
            >
              <Option key="AC" label="AC">
                AC
              </Option>
              <Option key="AL" label="AL">
                AL
              </Option>
              <Option key="AP" label="AP">
                AP
              </Option>
              <Option key="AM" label="AM">
                AM
              </Option>
              <Option key="BA" label="BA">
                BA
              </Option>
              <Option key="CE" label="CE">
                CE
              </Option>
              <Option key="DF" label="DF">
                DF
              </Option>
              <Option key="ES" label="ES">
                ES
              </Option>
              <Option key="GO" label="GO">
                GO
              </Option>
              <Option key="MA" label="MA">
                MA
              </Option>
              <Option key="MT" label="MT">
                MT
              </Option>
              <Option key="MS" label="MS">
                MS
              </Option>
              <Option key="MG" label="MG">
                MG
              </Option>
              <Option key="PA" label="PA">
                PA
              </Option>
              <Option key="PB" label="PB">
                PB
              </Option>
              <Option key="PR" label="PR">
                PR
              </Option>
              <Option key="PE" label="PE">
                PE
              </Option>
              <Option key="PI" label="PI">
                PI
              </Option>
              <Option key="RJ" label="RJ">
                RJ
              </Option>
              <Option key="RN" label="RN">
                RN
              </Option>
              <Option key="RS" label="RS">
                RS
              </Option>
              <Option key="RO" label="RO">
                RO
              </Option>
              <Option key="RR" label="RR">
                RR
              </Option>
              <Option key="SC" label="SC">
                SC
              </Option>
              <Option key="SP" label="SP">
                SP
              </Option>
              <Option key="SE" label="SE">
                SE
              </Option>
              <Option key="TO" label="TO">
                TO
              </Option>
            </Select>
          </div>
        </Panel>
        <Panel header="Ano de emissão" key="issueYear">
          <YearPicker
            suffixIcon={<Calendar width={24} height={24} />}
            value={issueYearFilter && moment(new Date(issueYearFilter, 0))}
            placeholder="Selecione o ano de emissão"
            onChange={(__, dateString) => {
              if (!dateString) {
                setIssueMonthFilter(() => undefined);
              }

              setIssueYearFilter(dateString);
            }}
            removeIcon={<CircleFalse />}
            style={{ width: 291 }}
          />
        </Panel>
        <Panel
          header={
            issueYearFilter ? (
              'Mês de emissão'
            ) : (
              <Tooltip
                title="Ano de emissão precisa ser selecionado"
                overlayClassName="review-filter-tooltip"
              >
                Mês de emissão
              </Tooltip>
            )
          }
          key="issueMonth"
          collapsible={issueYearFilter ? undefined : 'disabled'}
        >
          <MonthPicker
            suffixIcon={<Calendar width={24} height={24} />}
            dropdownClassName="month-picker-without-year"
            value={issueMonthFilter && moment(issueMonthFilter, 'MM')}
            placeholder="Selecione mês de emissão"
            onChange={(date) => {
              setIssueMonthFilter(date?.format('MM'));
            }}
            style={{ width: 291 }}
            format="MM"
            disabled={!issueYearFilter}
          />
        </Panel>
      </Collapse>

      <Checkbox
        className="double-check-filter"
        defaultChecked={doubleCheckFilter}
        checked={doubleCheckFilter}
        onChange={(e) => {
          setDoubleCheckFilter(e.target.checked);
        }}
      >
        Double Check
      </Checkbox>

      <div className="btn-filters mrg-right-15">
        <Button
          disabled={isLoadingList}
          onClick={updateFilters}
          className="btn-custom-primary"
        >
          Consultar
        </Button>
        <Button
          disabled={isLoadingList}
          block
          onClick={resetFilters}
          className="btn-custom-secondary"
        >
          Limpar filtros
        </Button>
      </div>
    </div>
  );
};

ReviewFilter.propTypes = {
  isLoadingList: PropTypes.bool,
};

ReviewFilter.defaultProps = {
  isLoadingList: false,
};

export default ReviewFilter;
