import React, { useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin, Menu } from 'antd';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import EmptyMessage from 'components/EmptyMessage';
import Loader from 'components/Loader';
import ZoomedControls from './components/ZoomedControls';

import './styles.less';
import '../common.less';

const ZoomedImage = ({
  src,
  alt,
  label,
  height: defaultHeight,
  showScaleButton,
  setKeyTab,
  showMenu = null,
  menuItems = null,
  keyTab = null,
  checkHover,
  useImageDefaultHeight,
  id,
  reduceInitialHeight,
  className,
  buttonsPosition,
  emptyMessageDescription,
  showLoupeButton,
  isLoupeActive,
  setIsLoupeActive,
  initialMaxHeight,
  imageRendering,
  isLiveness,
}) => {
  const imgRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showScale, setShowScale] = useState(true);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [defaultImgHeight] = useState(defaultHeight);

  const [style, setStyle] = useState({
    transform: 'rotate(0deg)',
    opacity: 0,
  });

  const [scaleVTStyle, setScaleVTStyle] = useState();
  const [scaleHLStyle, setScaleHLStyle] = useState();

  const onLoadImage = useCallback(() => {
    setLoading(false);

    let height;
    if (useImageDefaultHeight) {
      height = imgRef?.current?.height;
    } else {
      let elem;
      if (id) {
        elem = document?.getElementById(id);
      }

      if (!elem || (imgRef?.current?.width > imgRef?.current?.height && isLiveness)) {
        height = imgRef?.current?.height;
      } else {
        height = parseFloat(window.getComputedStyle(elem).height);
      }

      if (reduceInitialHeight) {
        height -= reduceInitialHeight;
      }
    }

    let maxHeight = null;
    if (initialMaxHeight) {
      maxHeight = initialMaxHeight;

      if (height > initialMaxHeight) {
        height = initialMaxHeight;
      }
    }

    setStyle((state) => ({
      ...state,
      height,
      opacity: 1,
      width: 'auto',
      maxHeight,
    }));
  }, [useImageDefaultHeight, id, reduceInitialHeight, initialMaxHeight, isLiveness]);

  const menuItem = useMemo(() => {
    const onClick = (e) => {
      setKeyTab(e.key);
    };

    if (menuItems) {
      return (
        <Menu
          className="zoomed-image-menu"
          onClick={onClick}
          mode="inline"
          openKeys={showSubmenu ? [`${label}-submenu`] : []}
        >
          <Menu.SubMenu
            title={menuItems?.filter((item) => item.key === keyTab)[0]?.label}
            className="zoomed-image-submenu"
            key={`${label}-submenu`}
            onTitleClick={() => {
              setShowSubmenu(!showSubmenu);
            }}
          >
            {menuItems?.map((item) => (
              <Menu.Item
                className="zoomed-image-menu-item"
                key={item.key}
                onClick={() => {
                  setShowSubmenu(false);
                }}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu>
      );
    }
    return <></>;
  }, [setKeyTab, menuItems, keyTab, label, showSubmenu]);

  return (
    <div
      id={id || undefined}
      style={{ height: `${defaultImgHeight + 2}rem` }}
      className={`zoomed-image-component ${className} ${classNames({
        scale: !showScale,
        'image-is-hovered': checkHover && isHovered,
      })}`}
      onMouseEnter={() => checkHover && setIsHovered(true)}
      onMouseLeave={() => checkHover && setIsHovered(false)}
    >
      <div className="hair" id="crosshair-h" style={scaleHLStyle} />
      <div className="hair" id="crosshair-v" style={scaleVTStyle} />
      {label && (
        <div className="zoomed-image-label">
          <span>{label}</span>
        </div>
      )}
      {showMenu && menuItem}

      {src && !error ? (
        <>
          {buttonsPosition === 'right-top' && (
            <ZoomedControls
              setStyle={setStyle}
              setScaleVTStyle={setScaleVTStyle}
              setScaleHLStyle={setScaleHLStyle}
              showScale={showScale}
              setShowScale={setShowScale}
              buttonsPosition={buttonsPosition}
              showScaleButton={showScaleButton}
              showLoupeButton={showLoupeButton}
              isLoupeActive={isLoupeActive}
              setIsLoupeActive={setIsLoupeActive}
            />
          )}
          <TransformWrapper
            minScale={0.1}
            maxScale={16}
            wheel={{ step: 0.6 }}
            panning={{ velocityDisabled: true }}
            limitToBounds={false}
          >
            <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
              <img
                ref={imgRef}
                src={src}
                alt={alt || label}
                style={style}
                onError={() => setError(true)}
                onLoad={() => onLoadImage()}
                id="img"
                className={`${classNames({
                  'rendering-pixelated': imageRendering === 'pixelated',
                })}`}
              />
            </TransformComponent>
          </TransformWrapper>
          {loading ? (
            <div className="loading">
              <Spin
                indicator={<Loader size="25px" color="#bdbdbd" />}
                tip="Carregando..."
              />
            </div>
          ) : (
            buttonsPosition === 'right-bottom' && (
              <ZoomedControls
                setStyle={setStyle}
                setScaleVTStyle={setScaleVTStyle}
                setScaleHLStyle={setScaleHLStyle}
                showScale={showScale}
                setShowScale={setShowScale}
                buttonsPosition={buttonsPosition}
                showScaleButton={showScaleButton}
                showLoupeButton={showLoupeButton}
                isLoupeActive={isLoupeActive}
                setIsLoupeActive={setIsLoupeActive}
              />
            )
          )}
        </>
      ) : (
        <div className="error">
          <EmptyMessage show type="image" description={emptyMessageDescription} />
        </div>
      )}
    </div>
  );
};

ZoomedImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.number,
  showScaleButton: PropTypes.bool,
  setKeyTab: PropTypes.func,
  showMenu: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.objectOf),
  keyTab: [PropTypes.number || PropTypes.objectOf(PropTypes.string)],
  checkHover: PropTypes.bool,
  useImageDefaultHeight: PropTypes.bool,
  id: PropTypes.string,
  reduceInitialHeight: PropTypes.number,
  className: PropTypes.string,
  buttonsPosition: PropTypes.string,
  emptyMessageDescription: PropTypes.string,
  showLoupeButton: PropTypes.bool,
  isLoupeActive: PropTypes.bool,
  setIsLoupeActive: PropTypes.func,
  initialMaxHeight: PropTypes.number,
  imageRendering: PropTypes.string,
  isLiveness: PropTypes.bool,
};

ZoomedImage.defaultProps = {
  src: '',
  alt: null,
  label: null,
  height: 400,
  showScaleButton: false,
  setKeyTab: () => {},
  showMenu: false,
  menuItems: [],
  keyTab: '',
  checkHover: false,
  useImageDefaultHeight: false,
  id: undefined,
  reduceInitialHeight: undefined,
  className: undefined,
  buttonsPosition: 'right-bottom',
  emptyMessageDescription: 'Imagem não encontrada',
  showLoupeButton: false,
  isLoupeActive: undefined,
  setIsLoupeActive: undefined,
  initialMaxHeight: undefined,
  imageRendering: undefined,
  isLiveness: false,
};

export default ZoomedImage;
