import React from 'react';
import { Typography } from 'antd';
import { Button } from '@combateafraude/react';

import { Link } from 'react-router-dom';

import rocket from 'assets/images/rocket.svg';

import Wrapper from '../wrapper';

import './styles.less';

const { Title } = Typography;

const Home = () => (
  <Wrapper id="home-page">
    <div id="home-page-content">
      <img src={rocket} alt="Foguete" />
      <Title className="title">Olá, seja bem vindo ao Wecheck</Title>
      <Link to="manual-review-wizard" className="gx-link mrg-top-10">
        <Button className="btn-custom-primary">Iniciar avaliação de documentos</Button>
      </Link>
    </div>
  </Wrapper>
);

export default Home;
