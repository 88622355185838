/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Card, Tooltip } from 'antd';
import { format } from 'date-fns';

import Loader from 'components/Loader';

import { useAuth } from 'hooks/auth';
import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { useCustomer } from 'hooks/customer';
import { toFormatDate } from 'utils/formatters';

import {
  AlertTriangle,
  Attach,
  Calendar,
  CheckD,
  CircleFalse,
  CircleTrue,
  Clock,
  Company,
  Eye,
  Link2,
  Map,
  ProcessingLine,
  Tag,
  Criminal,
  WrongCircle,
} from '@combateafraude/icons/general';

function translateType(type) {
  switch (type) {
    case 'documentscopy':
      return 'Documentoscopia';
    case 'pending-ocr':
      return 'Pendente OCR';
    case 'double-check-liveness':
      return 'Double-check de Liveness';
    default:
      return '-';
  }
}

const CardRecord = ({ data }) => {
  const { loggedUser } = useAuth();
  const hasPermissionToReportSuspect = hasRequiredPermissions(
    loggedUser,
    ['wecheck_reportSuspect:read'],
    []
  );
  const hasPermissionToIncorrectAnalysis = hasRequiredPermissions(
    loggedUser,
    ['wecheck_incorrectAnalysis:read'],
    []
  );

  const url = useMemo(
    () => data?._id && `${data.type}/records/details/record/${data._id}`,
    [data]
  );
  const avaliationData =
    data?.documentscopy || data?.pendingOcr || data?.doubleCheckLiveness;

  const { reportSuspect = null } = data?.executionInfo?.postEvaluationReview || {};
  const { incorrectAnalysis = null } = data?.postEvaluationReview || {};

  const { customersData: customers, loadingCustomers } = useCustomer();

  const client = useMemo(
    () =>
      customers?.docs?.find(
        (customer) => customer?.tenantId === data.executionInfo?.tenantId
      ),
    [customers?.docs, data.executionInfo?.tenantId]
  );

  const openInBackoffice = useCallback(() => {
    let backofficeUrlTail = '';
    if (
      (data.originalTenantId || data.executionInfo?.tenantId) &&
      data.executionInfo?.executionId
    ) {
      backofficeUrlTail = `executions/details/user/${
        data.originalTenantId ? data.originalTenantId : data.executionInfo.tenantId
      }/report/000000000000000000000000/execution/${data.executionInfo.executionId}`;
    }
    const backofficeUrl = `${process.env.REACT_APP_BASE_URL_BACKOFFICE_WEB}/${backofficeUrlTail}`;
    window.open(backofficeUrl, '_blank');
  }, [data]);

  return (
    <Card>
      <Link className="overflow-hidden document-card mrg-btm-20" to={url} target="_blank">
        <div className="card-body">
          <div className="flex-1 flex-column center-start">
            <div className="flex gx-w-100 mrg-btm-5">
              <p className="card-title max-lines-2 gx-w-100">
                {data?.executionInfo?.name ||
                  data?.executionInfo?.sections?.cpf?.name ||
                  data?.executionInfo?.sections?.pfBasicData?.data?.name ||
                  data?.executionInfo?.sections?.ocr?.name ||
                  data?.executionInfo?.sections?.ocrRedundancy?.name ||
                  data?.executionInfo?.sections?.pfData?.data?.name ||
                  data?.executionInfo?.sections?.globalDocVerification?.data?.name ||
                  '-'}
              </p>
            </div>

            <div className="flex gx-w-100">
              <div className="card-info">
                <Tag />

                <span className="mrg-left-5">
                  {translateType(data?.type?.toLowerCase())}
                </span>
              </div>

              <div className="card-info">
                <Attach />
                <span className="mrg-left-5">
                  {data?.executionInfo?.type?.toUpperCase() || '-'}
                </span>
              </div>

              <div className="card-info">
                <Map />
                <span className="mrg-left-5">
                  {data?.executionInfo?.sections?.ocr?.issueState ||
                    data?.executionInfo?.sections?.ocr?.cnhIssueState ||
                    '-'}
                </span>
              </div>

              <div className="card-info">
                <Clock />
                <span className="mrg-left-5">
                  Mês/ano de emissão:{' '}
                  {data?.executionInfo?.sections?.ocr?.issueDate?.slice(3) || '-'}
                </span>
              </div>

              <div className="card-info">
                <Calendar />
                <span className="mrg-left-5">
                  Data da criação: {toFormatDate(data?.createdAt)}
                </span>
              </div>
            </div>

            <div className="flex gx-w-100 mrg-top-15">
              <div className="card-info">
                <Company />

                {loadingCustomers ? (
                  <Loader size={10} color="#828282" className="mrg-left-5" />
                ) : (
                  client && (
                    <span className="mrg-left-5">
                      {client.fantasyName || client.companyName}
                    </span>
                  )
                )}
              </div>
              <div className="card-info">
                <Eye />
                <span className="mrg-left-5">
                  {data?.avaliationInfo?.evaluated ? 'Avaliado' : 'Aguardando avaliação'}
                </span>
              </div>

              {avaliationData && typeof avaliationData?.approved === 'boolean' && (
                <div
                  className={`card-info ${
                    avaliationData?.approved ? 'approved' : 'reproved'
                  }`}
                >
                  {avaliationData?.approved ? <CircleTrue /> : <CircleFalse />}

                  <span className="mrg-left-5">
                    {avaliationData?.approved ? 'Aprovado' : 'Reprovado'}
                  </span>
                </div>
              )}

              {avaliationData &&
                typeof avaliationData?.approved === 'boolean' &&
                !avaliationData.approved && (
                  <div className="card-info">
                    <AlertTriangle />
                    <span className="mrg-left-5">
                      Motivo da reprovação:{' '}
                      {avaliationData.fraud
                        ? 'Fraude'
                        : avaliationData.unreadable
                        ? 'Ilegibilidade'
                        : 'Geral'}
                    </span>
                  </div>
                )}

              {avaliationData &&
                typeof avaliationData?.doubleCheck === 'boolean' &&
                avaliationData.doubleCheck && (
                  <div className="card-info">
                    <CheckD />
                    <span className="mrg-left-5">Dupla verificação</span>
                  </div>
                )}

              {avaliationData &&
                typeof avaliationData?.reprocess === 'boolean' &&
                avaliationData?.reprocess && (
                  <div className="card-info">
                    <ProcessingLine />
                    <span className="mrg-left-5">Reprocessado</span>
                  </div>
                )}

              {hasPermissionToReportSuspect && reportSuspect?.isTrue && (
                <Tooltip title="Suspeito reportado">
                  <div className="card-info">
                    <Criminal />
                  </div>
                </Tooltip>
              )}

              {hasPermissionToIncorrectAnalysis && incorrectAnalysis?.isTrue && (
                <Tooltip title="Análise marcada como incorreta">
                  <div className="card-info">
                    <WrongCircle />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </Link>
      <div className="card-footer">
        <span className="gx-font-italic gx-font-weight-light">
          {data?.avaliationInfo && data?.avaliationInfo?.evaluated && (
            <>
              <b className="separator">•</b>
              Última modificação por {data?.avaliationInfo?.user?.username} em{' '}
              {data?.avaliationInfo?.reviewAt &&
                format(new Date(data?.avaliationInfo?.reviewAt), "dd/MM/yyyy 'às' HH:mm")}
            </>
          )}
        </span>

        <button type="button" onClick={openInBackoffice} className="btn-open">
          Abrir no Backoffice
          <Link2 />
        </button>
      </div>
    </Card>
  );
};

export default CardRecord;
